import { Container } from './styles';

const BookIntro = () => {

	return (
		<Container>
			<div className="featured-title">
				<h1 className="u--typo__title">🚀 Welcome to My Bookshelf! 📚</h1>
			</div>
			<p>
			People often reach out to me, curious about the programming books that have made a significant impact on my journey. 
			To make things easier, I've curated a collection of some must-read programming books that have not only shaped my understanding but have also been highly recommended by the tech community.
			</p>
			<p className='mt-3'>
			Some links are affiliate, meaning I may earn a commission if you decide to purchase through them. Please rest assured, this doesn't affect the price you pay; instead, it helps support this website and allows me to continue sharing valuable content.
			</p>
			
		</Container>
	);
};

export default BookIntro;
