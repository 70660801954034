import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	${({ fullScreen }) =>
		fullScreen &&
		css`
			height: calc(90vh - ${({ theme }) => theme.dimensions.navHeight});
		`}

	.tip {
		color: #3772ff;
		font-size: 14px;
		position: absolute;
		top: 50%;
		transform: translateY(30px);
	}
`;
