import { Container, FeaturedBlog } from './styles';
import titleIcon from '../../../assets/svgs/titleIcon.svg';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../constants';

const Featured = ({ featuredBlog }) => {
	const image = featuredBlog?.fields?.heroImage2?.fields?.file?.url;
	const title = featuredBlog?.fields?.title;
	const slug = featuredBlog?.sys?.id;
	const snippet = featuredBlog?.fields?.snippet;
	const formattedDate = new Date(featuredBlog?.fields?.createdOn)
		.toDateString()
		.split(' ')
		.splice(1)
		.reduce((acc, curr, index) => (index === 1 ? acc + curr + ', ' : acc + curr + ' '), '')
		.trim();

	return (
		<Container>
			<div className="featured-title">
				<h1 className="u--typo__title">Featured Post</h1>

				<img src={titleIcon} alt="titleIcon" loading="lazy" />
			</div>

			<div>
				<FeaturedBlog>
					<Link to={`${AppRoutes.blog}/${slug}`}>
						<img src={image} alt={title} loading="lazy" />

						<h4>{title}</h4>

						<p>{snippet}</p>
					</Link>

					<div className="featured-date">
						<span>{formattedDate}</span>

						<Link to={`${AppRoutes.blog}/${slug}`}>Read this</Link>
					</div>
				</FeaturedBlog>
			</div>
		</Container>
	);
};

export default Featured;
