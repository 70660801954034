import { createClient } from 'contentful';

// const contentfulClient = createClient({
// 	space: 'skouadv0pqif',
// 	accessToken: '1qA40HeBgBHj00Qs1yyfzuhNgMqyLl0q1WYdog6iZKE',
// });

const contentfulClient = createClient({
	space: 'skjfattbixn7',
	accessToken: 'PyqtfAtv_njfTc0zvGX_WJg2vWo531cEbl5M1tRRQ1Y',
});
export default contentfulClient;
