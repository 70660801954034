import styled from 'styled-components';
import { maxQuery } from '../../../helpers';

export const Container = styled.div`
	.featured-title {
		position: relative;

		h1 {
			position: relative;
			z-index: 1;
			margin-bottom: 1rem;
		}

		img {
			position: absolute;
			top: 1.1em;
			left: -1em;

			${maxQuery('md')} {
				left: -3%;
				/* right: 92.53%; */
				top: -15%;
				/* bottom: 95.52%; */
			}
		}
	}
`;

export const FeaturedBlog = styled.div`
	img {
		width: auto;
		height: auto;
		object-fit: cover;
		border-radius: 8px;
	}
	a {
		color: inherit;

		:hover h4 {
			text-decoration: underline;
		}
	}

	h4 {
		font-size: 1.75rem;
		font-weight: 700;
		line-height: 120%;
		margin-top: 1.5rem;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		${maxQuery('md')} {
			font-size: 1.6rem;
		}
		${maxQuery('sm')} {
			font-size: 1.5rem;
		}
	}

	p {
		margin: 1.25rem 0 1rem;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.featured-date {
		display: flex;
		justify-content: space-between;
		font-weight: 700;

		span {
			color: #64748b;
		}

		a {
			color: #126da9;
			margin-right: 3rem;

			${maxQuery('md')} {
				margin-right: 2rem;
			}
			${maxQuery('sm')} {
				margin-right: 1rem;
			}
		}
	}
`;
