import styled from 'styled-components';
import { maxQuery } from '../../helpers';

export const Container = styled.div`
	position: relative;

	img {
		width: auto;
		height: auto;
		object-fit: cover;
		border-radius: 8px;
	}

	.blog-tag {
		position: absolute;
		top: 27px;
		right: 9px;
		background: grey;
		padding: 6px 16px;
		font-weight: 700;
		font-size: 0.875rem;
		text-transform: capitalize;
		color: whitesmoke;
		border-radius: 360px;
	}

	h4 {
		font-size: 1.2rem;
		font-weight: 700;
		margin-top: 1rem;
		color: #141414;
		line-height: 120%;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		${maxQuery('md')} {
			font-size: 1.1rem;
			line-height: 33px;
		}
	}
	.blog-snippet {
		font-size: 17.17px;
		color: #393839;
		line-height: 25px;
		margin-top: 9.95px;

		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.blog-date {
		color: #000;
		opacity: 0.58;
		line-height: 120%;
		letter-spacing: 0.02em;
		font-size: 1rem;
		font-weight: 700;
		margin-top: 1rem;
		align-self: end;
	}
`;
