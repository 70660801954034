import { useState, useRef } from 'react';
import Pagination from '../../../UI/Pagination';
import { Category, Container } from './styles';
import Book from '../../../component/Book';

const Recent = ({ recentBooks, categories }) => {
	const [currentCategory, setCurrentCategory] = useState('all');
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(10);

	const pageRef = useRef(null);

	const indexOfLastItem = currentPage * postsPerPage;
	const indexOfFirstItem = indexOfLastItem - postsPerPage;

	const filteredPost = recentBooks?.filter((book) =>
		currentCategory === 'all' ? book : book?.fields?.category === currentCategory
	);

	const handleScrollIntoView = () => {
		pageRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Container ref={pageRef}>
			<div className="categories-container" style={{marginTop:"-75px"}}>
				{categories?.map((category) => (
					<Category
						className={currentCategory === category ? 'active' : ''}
						key={category}
						onClick={(e) => {
							setCurrentPage(1);
							setCurrentCategory(category);
						}}
					>
						{category}
					</Category>
				))}
			</div>

			<h3 className="u--typo__title2">Books</h3>

			<div className="post-container">
				{filteredPost?.slice(indexOfFirstItem, indexOfLastItem)?.map((book) => (
					<Book key={book?.sys?.id} book={book} />
				))}
			</div>

			<Pagination
				itemsPerPage={postsPerPage}
				length={filteredPost?.length}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				scrollPageToView={handleScrollIntoView}
			/>
		</Container>
	);
};

export default Recent;
