import { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Container } from './styles';
import { Button, LoadingScreen } from '../../UI';
import { ReactComponent as WhatsApp } from '../../assets/svgs/WhatsApp.svg';
import { ReactComponent as Copy } from '../../assets/svgs/Copy.svg';
import { ReactComponent as TwitterBlue } from '../../assets/svgs/TwitterBlue.svg';
import { ReactComponent as FacebookBlue } from '../../assets/svgs/FacebookBlue.svg';
import Blog from '../../component/Blog';
import NotFound from '../../component/NotFound';
import { useSelector } from 'react-redux';
import { contentfulClient } from '../../config';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { AppRoutes } from '../../constants';

const BlogDetails = () => {
	const { blogs } = useSelector((state) => state.AppReducer);
	const { id } = useParams();
	const { pathname } = useLocation();

	const [blog, setBlog] = useState();
	const [blogStates, setBlogStates] = useState({ loading: true, fetchSuccess: true });

	const formattedDate = new Date(blog?.fields?.createdOn)
		.toDateString()
		.split(' ')
		.splice(1)
		.reduce((acc, curr, index) => (index === 1 ? acc + curr + ', ' : acc + curr + ' '), '')
		.trim();
	const relatedArticles = blogs?.recent.filter(
		(post) => post?.category === blog?.category && post?.sys?.id !== id
	);

	const BASE_URL = 'https://ridwanray.com';
	const PAGE_URL = BASE_URL + pathname;

	const contentfulOptions = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				console.log(node, children);
				return (
					<figure>
						<img
							src={node?.data?.target?.fields?.file?.url}
							alt={node?.data?.target?.fields?.description}
							loading="lazy"
						/>

						<figcaption>{node?.data?.target?.fields?.description}</figcaption>
					</figure>
				);
			},
			[INLINES.ENTRY_HYPERLINK]: (node, children) => (
				<Link to={`${AppRoutes.blog}/${node?.data?.target?.sys?.id}`}>{node?.content[0]?.value}</Link>
			),
		},
	};

	const copyLink = () => {
		navigator.clipboard.writeText(PAGE_URL).then(
			(success) => alert('Link has been copied'),
			(err) => alert('Was unable to copy text')
		);
	};

	useEffect(() => {
		const getEntriesById = async () => {
			setBlogStates((prev) => ({ ...prev, loading: true }));
			try {
				await contentfulClient.getEntry(id).then((entry) => {
					console.log(entry);
					setBlog(entry);
					setBlogStates((prev) => ({ ...prev, loading: false, fetchSuccess: true }));
				});
			} catch (error) {
				console.log(error);
				setBlogStates((prev) => ({ ...prev, loading: false, fetchSuccess: false }));
			} finally {
				setBlogStates((prev) => ({ ...prev, loading: false }));
			}
		};
		getEntriesById();
	}, [id]);

	if (blogStates.loading === true) {
		return <LoadingScreen fullScreen />;
	}
	if (blogStates.loading === false && blogStates.fetchSuccess === false) {
		return <NotFound />;
	}

	return (
		<Container 
			// heroImg={blog?.fields?.heroImage2?.fields?.file.url}
			// //mobileImg={blog?.fields?.mobileHeroImage?.fields?.file.url}
		>
			<div className="hero justify-content-center">
				<div className="hero--content ">
					<h1>{blog?.fields?.title}</h1>

					<div>
						<span>{formattedDate}</span>
						<span className="divider"></span>
						<span>{blog?.fields?.timeToRead} mins read</span>
					</div>

					<h3 className="author">{blog?.fields?.authorName}</h3>
				</div>
			</div>

			<div className="body ">
				<div className="body--head" style={{margin:"15px"}}>
					<div className="category">{blog?.fields?.category}</div>

					<div className="social--links">
						<Button secondary onClick={copyLink}>
							<Copy />
							<span>Copy link</span>
						</Button>
						<Button secondary>
							<a
								href={`whatsapp://send?text=${PAGE_URL}`}
								action="share/whatsapp/share"
								target="_blank"
								rel="noopener noreferrer"
							>
								<WhatsApp />
							</a>
						</Button>
						<Button secondary>
							<a href={`http://www.twitter.com/share?url=${PAGE_URL}`} target="_blank" rel="noopener noreferrer">
								<TwitterBlue />
							</a>
						</Button>
						<Button secondary>
							<a
								href={`https://www.facebook.com/sharer/sharer.php?u=${PAGE_URL}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FacebookBlue />
							</a>
						</Button>
					</div>
				</div>

				<section className="body--content"
				style={{marginRight:"50px", marginLeft:"50px"}} >
					{documentToReactComponents(blog?.fields?.blogContent, contentfulOptions)}
				</section>

				<div className='text-center' style={{marginRight:"50px", marginLeft:"50px"}}>
			Ready to take your Coding skills to the next level ? Check <a href="https://www.youtube.com/@ridwanray" target="_blank" rel="noopener noreferrer">Youtube</a>  for Exclusive Content. Consider Subscribing to stay tuned.
      			 
    		</div>

			</div>


			{/* <div className="newsletter">
				<NewsLetterForm
					heading="Subscribe to Ridwanray NewsLetter"
					label="Get our latest post straight into your inbox."
					flexed
				/>
			</div> */}

			{relatedArticles.length > 0 && (
				<div className="related m-2">
					<h3>Related Articles</h3>

					<div className="cards m-5">
						{relatedArticles.slice(0, 3).map((blog) => (
							<Blog key={blog?.sys?.id} blog={blog} />
						))}
					</div>
				</div>
			)}

		</Container>
	);
};

export default BlogDetails;
