import React from 'react';
import { Link } from 'react-router-dom';

const TopNav = () => {

	return (
			<section className="navbar-area navbar-nine">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<nav className="navbar navbar-expand-lg">
								<a className="navbar-brand" href="/">
									<span className="fw-bold text-lg text-white">Ridwanray</span>
								</a>
								<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine"
									aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation">
									<span className="toggler-icon"></span>
									<span className="toggler-icon"></span>
									<span className="toggler-icon"></span>
								</button>

								<div className="collapse  navbar-collapse sub-menu-bar justify-content-center" id="navbarNine">
									<ul className="navbar-nav justify-content-center">
										<li className="nav-item">
											<Link to="/" className="page-scroll active">Home</Link>
										</li>
										<li className="nav-item">
											<Link to="/coaching" className="page-scroll active">Coaching</Link>
										</li>

										<li className="nav-item">
											<Link to="/blog" className="page-scroll active">Blog</Link>
										</li>

										<li className="nav-item">
											<Link to="/books" className="page-scroll active">Books</Link>
										</li>
								
									</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>

			</section>
	);
};

export default TopNav;
