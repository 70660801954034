import Featured from './Featured';
import Recommended from './Recommended';
import Recent from './Recent';
import { Container } from './styles';
import { useSelector } from 'react-redux';
import { LoadingScreen } from '../../UI';

const Blog = () => {
	const { blogs } = useSelector((state) => state.AppReducer);

	if (!(blogs?.recent?.length > 0)) {
		return <LoadingScreen fullScreen />;
	}

	return (
		<Container>
			<header>
				<Featured featuredBlog={blogs?.featured} />

				<Recommended recommendedBlogs={blogs?.recommended} />
			</header>

			<Recent recentBlogs={blogs?.recent} categories={blogs?.categories} />

			{/* <div className="news-letter-container">
				<NewsLetterForm
					heading="Subscribe to our Newsletter"
					label="Get our latest post straight in your inbox."
					flexed
				/>
			</div> */}
		</Container>
	);
};

export default Blog;
