import { Link } from 'react-router-dom';
import { Container } from './styles';

const Recommended = ({ recommendedBlogs }) => {
	return (
		<Container>
			{recommendedBlogs?.length > 0 && (
				<>
					<h3 className="u--typo__title2">Highly Recommended</h3>

					<div className="blogs-con">
						{recommendedBlogs?.map((post) => (
							<RecommendedBlog key={post?.sys?.id} post={post} />
						))}
					</div>
				</>
			)}

			<div style={{ fontSize: "15px", fontWeight: "bold" }}>
      			Subscribe on <a href="https://www.youtube.com/@ridwanray" target="_blank" rel="noopener noreferrer">Youtube</a> to get notified when new videos are published
    		</div>
			
			{/* <NewsLetterForm
				label="Get updates directly into your mail. No spamming."
				buttonText="Subscribe to our newsletter"
			/> */}
		</Container>
	);
};

export default Recommended;

const RecommendedBlog = ({ post }) => {
	const formattedDate = new Date(post?.fields?.createdOn)
		.toDateString()
		.split(' ')
		.splice(1)
		.reduce((acc, curr, index) => (index === 1 ? acc + curr + ', ' : acc + curr + ' '), '')
		.trim();

	return (
		<Link key={post?.sys?.id} to={`/blog/${post?.sys?.id}`} className="blog-item">
			<img src={post?.fields?.heroImage2?.fields?.file?.url} alt={post?.fields?.title} loading="lazy" />

			<div>
				<h5>{post?.fields?.title}</h5>

				<span>{formattedDate}</span>
			</div>
		</Link>
	);
};
