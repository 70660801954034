import styled from 'styled-components';
import { maxQuery } from '../../helpers';

export const Container = styled.div`
	margin-top: 80px;

	.page-btn-container {
		display: flex;
		justify-content: space-between;

		.prev-btn,
		.next-btn {
			color: #344054;
			display: flex;
			gap: 8px;
			align-items: center;
			padding: 8px 14px;
			font-family: inherit;
			border: 1px solid #c7c7c7;
			box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
			border-radius: 8px;
			background: #f7f8f9;
			outline: none;

			span {
				${maxQuery('md')} {
					display: none;
				}
			}
		}

		.prev-btn:disabled,
		.next-btn:disabled {
			opacity: 0.5;
		}

		.rotate-prev {
			transform: rotate(180deg);
		}

		.page-number-container {
			display: flex;
			/* gap: 4px; */

			.pages-mobile {
				display: none;
				color: #344054;
				font-weight: 600;
				line-height: 20px;
				font-size: 14px;

				${maxQuery('md')} {
					display: block;
				}
			}

			button.page-number {
				background: transparent;
				border: none;
				outline: none;
				font-family: inherit;
				font-size: 14px;
				padding: 10px 16px;
				border-radius: 10px;
				transition: background 0.2s ease-in;

				&.active,
				&:hover {
					background: #f9f5ff;
					color: #7f56d9;
				}

				${maxQuery('md')} {
					display: none;
				}
			}

			.page-dots {
				font-weight: 800;

				${maxQuery('md')} {
					display: none;
				}
			}
		}
	}
`;
