import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { Container } from './styles';

const Blog = ({ blog, inHome = false }) => {
	const slug = blog?.sys?.id;
	const image = blog?.fields?.heroImage2?.fields?.file?.url;
	const title = blog?.fields?.title;
	const category = blog?.fields?.category;
	const snippet = blog?.fields?.snippet;
	const formattedDate = new Date(blog?.fields?.createdOn)
		.toDateString()
		.split(' ')
		.splice(1)
		.reduce((acc, curr, index) => (index === 1 ? acc + curr + ', ' : acc + curr + ' '), '')
		.trim();

	return (
		<Container>
			<Link to={`${AppRoutes.blog}/${slug}`}>
				<img src={image} alt={title} loading="lazy" />

				{inHome === false && <span className="blog-tag">{category}</span>}

				<h4 title={title}>{title}</h4>

				<p className="blog-snippet">{snippet}</p>

				{inHome === false && <span className="blog-date">{formattedDate}</span>}
			</Link>
		</Container>
	);
};

export default Blog;
