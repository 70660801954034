import BookIntro from './BookIntro';
import Recent from './Recent';
import { Container } from './styles';
import { useSelector } from 'react-redux';
import { LoadingScreen } from '../../UI';

const Book = () => {
	const { books } = useSelector((state) => state.AppReducer);

	if (!(books?.recent?.length > 0)) {
		return <LoadingScreen fullScreen />;
	}

	return (
		<Container>
			<header>
				<BookIntro/>
			</header>

			<Recent recentBooks={books?.recent} categories={books?.categories} />

		</Container>
	);
};

export default Book;
