import styled from 'styled-components';
import { maxQuery } from '../../helpers';

export const Container = styled.section`
	margin-top: 5rem;
	padding: 5em;

	header {
		display: flex;
		gap: 4rem;

		${maxQuery('xl')} {
			flex-direction: column;
		}
	}

	.news-letter-container {
		padding: 10rem 0;
	}
`;
