import React from 'react';
import {Link } from 'react-router-dom';
// import Blogs from './Blogs';
import { AppRoutes } from '../../constants';

const Home = () => {
	return (
		<>
			<section id="hero-area" className="header-area header-eight">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 col-12">
							<div className="header-content">
								<h1>Take Your Coding Skills To The Next Level 🚀</h1>
								<p>
									Embarking on the journey of coding can feel like navigating a complex maze, and that's where I come in – your dedicated guide through the intricate world of programming.
								</p>
								<div className="button">
									<Link to="/blog" className="btn primary-btn">Technical Articles & Tips</Link>
								</div>
					
								<div className=" fw-bold mt-5  d-f justify-content-center">
									<a style={{ color: 'white', marginRight: '10px' }} href="https://www.youtube.com/@ridwanray" target="_blank" rel="noopener noreferrer">
										<i className="lni lni-youtube"></i>
										<span>Youtube</span>
									</a>


									<a style={{ color: 'white', marginRight: '10px' }} href="https://www.github.com/ridwanray" target="_blank" rel="noopener noreferrer">
										<i className="lni lni-github-original"></i>
										<span>GitHub</span>
									</a>
					


									<a style={{ color: 'white', marginRight: '10px' }} href="https://www.linkedin.com/in/ridwanray/" target="_blank" rel="noopener noreferrer">
										<i className="lni lni-linkedin-original"></i>
										<span>LinkedIn</span>
									</a>


									{/* <a style={{ color: 'white', marginRight: '10px' }} href="https://ridwanray.medium.com/" target="_blank" rel="noopener noreferrer">
										<i className="lni lni-medium"></i>
										<span>Medium</span>
									</a> */}
								</div>

							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-12">
							{/* <div className="header-image">
            <img src="assets/images/blog/home.jpeg" alt="#" />
          </div>  */}
						</div>
					</div>
				</div>
			</section>

			<section id="services" className="services-area services-eight">

				<div className="section-title-five">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="content">
									<h2 className="fw-bold">Code Your Next Project</h2>
								</div>
							</div>
						</div>

					</div>

				</div>

				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="single-services">
								<div className="service-icon">
									<i className="lni lni-book"></i>
								</div>
								<div className="service-content">
									<h4>Simplified Courses</h4>
									<p>
										Dive into our comprehensive coding courses designed to simplify the intricate world of programming.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services">
								<div className="service-icon">
									<i className="lni lni-code"></i>
								</div>
								<div className="service-content">
									<h4>Clean Code</h4>
									<p>
										From mastering languages to launching your dream project, learn everything you need to write, deploy, and scale your applications.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services">
								<div className="service-icon">
									<i className="lni lni-rocket"></i>
								</div>
								<div className="service-content">
									<h4>Practical Projects</h4>
									<p>
										Engage with hands-on projects that reinforce your learning and allow you to apply your newfound skills in real-world scenarios.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- ===== service-area end ===== --> */}


			{/* <!-- Start Cta Area --> */}
			<section id="about-section" className="call-action">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
							<div className="inner-content ">
								<h2>Meet Ridwan</h2>
								<p className="text-start">
									I'm a Software Engineer passionate about using web technologies to build amazing products and
									focusing on solving problems for different niches and different
									industries using the power of technology.
								</p>

								<p className="text-start mt-3">
									I love breaking down complex concepts by showing you how to implement them in project-based lessons.
									Whether you're just starting out or looking to improve your skills, I've got you covered with tutorials and tips.
								</p>
								<p className='text-start mt-3'>
								Whether you have a business idea, a development project, or any other venture, let's make it a reality. 👇
								</p>

								<div className="light-rounded-buttons">
									<a href="https://forms.gle/SKASK53bGJhumj8g6" target="_blank" className="btn primary-btn-outline" rel="noopener noreferrer">Initiate Project Execution</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div>
					
				</div>
			</section>
			{/* <!-- End Cta Area --> */}

			{/* <!-- Start Latest News Area --> */}
			<div id="blog" className="latest-news-area section">
				{/* <!--======  Start Section Title Five ======--> */}
				<div className="section-title-five">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="content">
									<h2 className="fw-bold">Few picks from the blog</h2>
								</div>
							</div>
						</div>
					</div>

				</div>
				{/* <!--======  End Section Title Five ======--> */}
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-12">
							{/* <!-- Single News --> */}
							<div className="single-news">
								<div className="content-body">
									<h4 className="title">
										{/* <a href="javascript:void(0)">Complete Django API on AWS EC2 (Nginx, Gunicorn, Celery, Flower dashboard, and Certbot) </a> */}
										<Link to={`${AppRoutes.blog}/1hh4ToO69jUKSrTwwrE5XS`}>
										Scope in Python and the LEGB rule explained.
										</Link>
									</h4>
								</div>
							</div>
							{/* <!-- End Single News --> */}
						</div>
						<div className="col-lg-4 col-md-6 col-12">
							{/* <!-- Single News --> */}
							<div className="single-news">

								<div className="content-body">
									<h4 className="title">
										{/* <a href="javascript:void(0)">
											Implementing Two-Factor Authentication (2FA) with Django and Django Rest Framework (DRF)
										</a> */}
										<Link to={`${AppRoutes.blog}/6ZTU5lDwwUPfwpVjWL3T7K`}>
										Context Manager in Python using the 'with' statement
										</Link>
										
									</h4>
								</div>
							</div>
							{/* <!-- End Single News --> */}
						</div>
						<div className="col-lg-4 col-md-6 col-12">
							{/* <!-- Single News --> */}
							<div className="single-news">
								<div className="content-body">
									<h4 className="title">
										{/* <a href="javascript:void(0)">
											Comprehensive Guide: Deploy Static Websites and SPAs (React/Vue/Angular) to AWS S3 Buckets
										</a> */}
										<Link to={`${AppRoutes.blog}/6Wj9tMffFuESFXW3RhUHZR`}>
										Instance, Class, and Static Method explained
										</Link>
										
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
