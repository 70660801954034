import styled from 'styled-components';
import { maxQuery } from '../../../helpers';

export const Container = styled.div`
	width: 380px;
	max-width: 100%;
	flex-shrink: 0;

	${maxQuery('xl')} {
		width: 100%;
	}

	h3 {
		margin-bottom: 1rem;
	}

	.blogs-con {
		margin-bottom: 2.5rem;

		${maxQuery('xl')} {
			display: grid;
			gap: 2rem;
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
		${maxQuery('sm')} {
			grid-template-columns: 1fr;
		}
	}
	.blog-item {
		display: flex;
		align-items: center;
		gap: 0.75rem;

		&:not(:last-child) {
			margin-bottom: 1rem;

			${maxQuery('xl')} {
				margin-bottom: 0;
			}
		}

		img {
			--size: 70px;

			width: 50%;
			height: auto;
			object-fit: cover;
			border-radius: 8px;
		}

		h5 {
			font-size: 1.15rem;
			font-weight: 700;
			line-height: 120%;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

			${maxQuery('sm')} {
				font-size: 1rem;
			}
		}

		span {
			color: #000;
			font-weight: 700;
			line-height: 120%;
			color: #64748b;
		}
	}
`;
