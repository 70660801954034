import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
  return (
    <footer className="footer-area footer-eleven">
      <div className="footer-top">
        <div className="container">
          <div className="inner-content">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-widget f-about">
                  <div className="logo">
                    <span className="fw-bold text-lg">Ridwanray</span>
                  </div>
                  <p>
                    Coding Made Simple
                  </p>

                  <p>
                    Start building real-world projects by leveraging technologies.
                  </p>
                </div>

              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget f-link">
                  <h5>Social</h5>
                  <ul>
                    <li><a href="https://www.youtube.com/@ridwanray" target="_blank" rel="noopener noreferrer">Youtube</a></li>
                    <li><a href="https://www.linkedin.com/in/ridwanray/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    <li><a href="https://www.github.com/ridwanray" target="_blank" rel="noopener noreferrer">GitHub</a></li>
                    {/* <li><a href="https://ridwanray.medium.com/" target="_blank" rel="noopener noreferrer">Medium</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget f-link">
                  <h5>Explore</h5>
                  <ul>
                    <li><a href="https://www.youtube.com/@ridwanray" target="_blank" rel="noopener noreferrer">Tutorials</a></li>
                    <li><Link to="/blog" className="page-scroll">Blog</Link></li>
                    <li><Link to="/books" className="page-scroll">Books</Link></li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12  f-link">
                <div className="footer-widget newsletter">
                  <h5>Business</h5>
                  <p>Contact: business@ridwanray.com</p>
                  <ul>
                    <li><a href="https://forms.gle/8hyYe4r9CJZerQaHA" target="_blank" rel="noopener noreferrer">Work with me</a></li>
                    {/* <a href="https://forms.gle/8hyYe4r9CJZerQaHA" target="_blank" className="btn primary-btn-outline" rel="noopener noreferrer">Get Coaching Assistance</a> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
