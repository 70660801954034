import { Container } from './styles';

const Book = ({ book}) => {
	const image = book?.fields?.thumbNail?.fields?.file?.url;
	const title = book?.fields?.title;
	const url_link = book?.fields?.link;

	return (
		<Container>
			<a href={url_link} target="_blank" rel="noopener noreferrer">
				<img src={image} alt={title} loading="lazy" />

				<h5 title={title}>{title}</h5>
			</a>
		</Container>
	);
};

export default Book;
