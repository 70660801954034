import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export default styled.button`
	&:disabled {
		cursor: no-drop;
	}
	cursor: pointer;
	outline: none;
	${(props) => css`
		padding: 0.8em 1.8em;
		font-size: 0.9rem;
		border-radius: 3px;
		font-weight: 600;
		user-select: none;
		width: ${props.full ? '100%' : 'fit-content'};
		display: ${props.full ? 'block' : 'inline-block'};
		${props.primary &&
		css`
			border: 2px solid ${props.theme.secondary};
			background-color: ${({ theme }) => theme.secondary};
			color: #fff;
			z-index: 3;
			border-radius: 7px;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				height: 100%;
				border-radius: 7px;
				top: 5px;
				left: 5px;
				width: 100%;
				background: pink;
				background: transparent;
				z-index: -2;
				border: 0.4px solid ${props.theme.secondary};
			}
			&:hover,
			&:focus {
				background: ${({ theme }) => theme.primary};
				border-color: ${({ theme }) => theme.primary};
				color: #fff;
				outline: none;
				&:after {
					border: 0.4px solid ${props.theme.primary};
				}
			}
			&:disabled {
				background: ${({ theme }) => theme.disabled}!important;
				border-color: ${({ theme }) => theme.disabled}!important;
				color: #fff !important;
			}
		`}
		${props.secondary &&
		css`
			border: 1px solid !important;
			border-color: ${({ theme }) => theme.secondary};
			background-color: transparent !important;
			color: ${props.theme.secondary};
			&:hover,
			&:focus {
				background: transparent !important;
				border-color: ${({ theme }) => lighten(0.1, theme.secondary)}!important;
				color: ${({ theme }) => lighten(0.1, theme.secondary)}!important;
			}
			&:disabled {
				background: transparent !important;
				color: ${props.theme.disabled}!important;
			}
		`}
    ${props.tertiary &&
		css`
			background-color: transparent;
			border-color: transparent;
			color: ${({ theme }) => theme.secondary};
			&:hover,
			&:focus {
				border-color: transparent !important;
				background-color: transparent !important;
				color: ${({ theme }) => theme.secondary}!important;
			}
		`}
    ${props.plain &&
		css`
			background-color: transparent;
			border: none;
			color: #000000;
			padding: 0px;
			&:hover,
			&:focus {
				color: ${({ theme }) => lighten(0.1, theme.primary)};
				border-color: transparent !important;
				background-color: transparent !important;
			}
		`}
    ${props.icon &&
		css`
			padding: 0px;
			background: transparent;
			border: none;
			.icon {
				font-size: 1.2em;
				margin: 0px;
				padding: 0px;
			}
			&:hover,
			&:focus {
				background: transparent;
				border: none;
				outline: none;
			}
			&:disabled {
				background: transparent;
				border: none;
				color: #fff;
				outline: none;
			}
		`}
    &.icon--btn__hover {
			&:hover,
			&:focus {
				color: #fff;
				background-color: ${({ theme }) => lighten(0.1, theme.primary)};
			}
		}

		${props.spinnerWithTxt &&
		css`
			display: flex !important;
			grid-gap: 0.5em;
			justify-content: center;
			align-items: center !important;
		`}
		/* iconRight */
    ${props.iconRight &&
		css`
			display: flex;
			align-items: center;
			.icon {
				margin-right: 0.3em;
				font-size: 1.2em;
			}
		`}

  /* IconLeft */
    ${props.iconLeft &&
		css`
			display: flex;
			align-items: center;
			.icon {
				margin-left: 0.3em;
				font-size: 1.2em;
			}
		`}

     &.btn--money {
			padding: 0.2em 0.3em;
			background: #e2ffec;
			.icon {
				color: #47c479;
			}
		}
		&.btn--edit {
			padding: 0.2em 0.3em;
			background: #e2edff;
			border: 1px solid #e2edff;
			.icon {
				color: #4187ff;
			}
		}
		&.btn--delete {
			padding: 0.2em 0.3em;
			background: #ffe9e9;
			color: #ff5e5e;
			border: 1px solid #ffe9e9;
			.icon {
				color: #ff5e5e;
			}
		}
		&.btn--copy {
			padding: 0.2em 0.3em;
			background: #f9f6fb;
			color: #855aaf;
			border: 1px solid #f9f6fb;
			.icon {
				color: #855aaf;
			}
		}
		/* notification */
		&.notification--badge {
			position: relative;
			&:after {
				content: '';
				top: -2px;
				right: 0%;
				position: absolute;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #ff5e5e;
				border: 1.6px solid #fff;
			}
		}
	`}
`;
