import { contentfulClient } from '../../config';

export const setUseLayout = (data) => (dispatch) => {
	dispatch({
		type: 'SET_USE_LAYOUT',
		data,
	});
};

export const getBlogs = () => async (dispatch) => {
	try {
		contentfulClient.getEntries().then((entries) => {
			// Sort by date, newest first pageBlogPost ydevBlogs
			const sortedBlogs = entries?.items
				?.filter((blog) => blog?.sys?.contentType?.sys?.id === 'pageBlogPost')
				.sort((a, b) => new Date(b?.fields?.createdOn) - new Date(a?.fields?.createdOn));

			// Get all categories from the entries
			const categories = [...new Set(sortedBlogs?.map((blog) => blog?.fields?.category))];
			const blogs = {
				featured: sortedBlogs[0],
				recommended: sortedBlogs?.slice(1, 4),
				recent: [...sortedBlogs],
				categories: ['all', ...categories],
			};

			dispatch({
				type: 'ALT_BLOGS',
				data: blogs,
			});
		});
	} catch (error) {
		console.log(error);

		dispatch({
			type: 'ALT_BLOGS',
			data: {
				featured: {},
				recommended: [],
				recent: [],
				categories: ['all'],
			},
		});
	}
};

export const getBookRecommendation = () => async (dispatch) => {
	try {
		contentfulClient.getEntries().then((entries) => {
			// Sort by date, newest first pageBlogPost ydevBlogs
			const sortedBooks = entries?.items
				?.filter((book) => book?.sys?.contentType?.sys?.id === 'bookRecommendation')

			// Get all categories from the entries
			const categories = [...new Set(sortedBooks?.map((book) => book?.fields?.category))];

			const books = {
				recent: [...sortedBooks],
				categories: ['all', ...categories],
			};
			console.log("books", books)

			dispatch({
				type: 'ALT_BOOKS',
				data: books,
			});
		});
	} catch (error) {
		console.log(error);

		dispatch({
			type: 'ALT_BOOKS',
			data: {
				recent: [],
				categories: ['all'],
			},
		});
	}
};
