import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { TopNav, Footer } from './Layout';
import { useDispatch, } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
	Home,
	Blog,
	BlogDetails,
	Coaching,
	Book,
} from './View';
import theme from './base/theme';
import { getBlogs, getBookRecommendation } from './redux/actions';
import { AppRoutes } from './constants';
import { NotFound } from './component';
import GlobalStyle from './base/globalStyles';
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [pathname]);

	return null;
};

const App = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		dispatch(getBlogs());
		dispatch(getBookRecommendation());
	}, [dispatch, location.pathname]);

	return (

		<ThemeProvider theme={{ ...theme() }}>
			<GlobalStyle />
			<TopNav {...{ location }} />
			<Switch>
				<Route path="/" exact={true}>
					<Redirect to={AppRoutes.home} />
				</Route>
				<Route path={AppRoutes.home} component={Home} />
				<Route exact path={AppRoutes.blog} component={Blog} />
				<Route exact path={AppRoutes.books} component={Book} />
				<Route exact path={AppRoutes.coaching} component={Coaching} />
				<Route path={`${AppRoutes.blog}/:id`} component={BlogDetails} />
				<Route path={'*'} component={NotFound} />
			</Switch>
			<Footer />
			<ScrollToTop />

		</ThemeProvider>
	);
};

export default App;
