import styled, { css } from 'styled-components';
import { maxQuery } from '../../helpers';

export const Container = styled.article`
    margin-top: 0;
    margin-bottom: 70px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: 100vh;

	.hero {
		position: relative;
		height: calc(100vh - ${({ theme }) => theme.dimensions.navHeight});
		display: flex;
		align-items: center;
		min-height: 450px;
		max-height: 100px;

		${maxQuery('lg')} {
			height: calc(100vh - ${({ theme }) => theme.dimensions.navHeightMobile});
		}
		::before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			min-width: 400px;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-size: cover;
			//background-image: ${(props) => `url('${props.heroImg}')`};
			background-color: grey;
			background-repeat: no-repeat;
			background-position: center;

			${(props) =>
				props?.mobileImg &&
				css`
					${maxQuery('md')} {
						background-image: url('${props.mobileImg}');
					}
				`}
		}
		::after {
			content: '';
			position: absolute;
			width: 100vw;
			min-width: 400px;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0.55;
			background-color: #000000;
		}

		.hero--content {
			color: #fff !important;
			z-index: 5;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			h1 {
				color: #fff;
				font-weight: 800;
				font-size: 2.5rem;
				line-height: 170%;

				${maxQuery('md')} {
					font-size: 2rem;
				}
				${maxQuery('sm')} {
					font-size: 1.7rem;
				}
			}
			div {
				display: flex;
				gap: 0.5rem 1rem;
				font-weight: 600;
				font-size: 1.5rem;
				line-height: 120%;
				text-transform: capitalize;
				flex-wrap: wrap;

				${maxQuery('md')} {
					font-size: 1.3rem;
				}
				${maxQuery('sm')} {
					font-size: 1.1rem;
				}
				.divider {
					border-right: 3px solid #fff;
				}
			}
			h3 {
				color: #fff;
				font-weight: 700;
				font-size: 1.2rem;
				line-height: 120%;
				letter-spacing: 0.02em;

				${maxQuery('md')} {
					font-size: 1rem;
				}
			}
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		margin: 1rem 0 0 0;
		gap: 2.2rem;
		${maxQuery('md')} {
			gap: 1rem;
		}

		.body--head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			.category {
				background: #f2fcff;
				border-radius: 50px;
				font-weight: 700;
				font-size: 1.2rem;
				text-transform: capitalize;
				padding: 0.58em 2em;

				${maxQuery('md')} {
					font-size: 1rem;
				}
				${maxQuery('sm')} {
					font-size: 0.875rem;
				}
			}
			.social--links {
				display: flex;
				align-items: center;
				gap: 0.6rem;

				> * {
					border: 1px solid #000000;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 700;
					height: 42px;

					${maxQuery('md')} {
						--size: 24px;
						height: 35px;
						padding: 0.6em 1.3em;
					}
					${maxQuery('sm')} {
						--size: 20px;
						height: 32px;
					}
				}
				> *:not(:first-child) {
					padding: 0;

					a {
						padding: 0.2rem 0.3rem;
					}

					${maxQuery('md')} {
						width: 35px;

						svg {
							width: var(--size);
							height: var(--size);
						}
					}
					${maxQuery('sm')} {
						width: 32px;
					}
				}
				> *:first-child {
					svg {
						--size: 16px;

						display: none;
						width: var(--size);
						height: var(--size);
					}

					${maxQuery('sm')} {
						padding: 0.2rem 0.3rem;
						width: 32px;

						span {
							display: none;
						}
						svg {
							display: block;
						}
					}
				}
			}
		}
		.body--content {
			font-size: 1.2rem;
			line-height: 180%;

			${maxQuery('md')} {
				font-size: 1.1rem;
			}
			${maxQuery('sm')} {
				font-size: 1rem;
			}
			ol {
				list-style: decimal;
			  }
		  
			hr {
				border-top: 2px solid ${({ theme }) => theme.colors.neutral800};
				margin-top: 1.7rem;
			}

			a {
				color: #000000;
				text-decoration: underline;
			}

			p {
				font-size: inherit;
				line-height: inherit;
				margin: 1.7em 0;
			}
			p,
			ol,
			ul {
			${({ theme }) => theme.mixins.paragraph_03};
			color: ${({ theme }) => theme.colors.black};
			}

			ul {
			list-style: disc;
			padding-left: 1.3rem;

			li {
				margin-top: 1.5rem;
			}
			}
			h4 {
				font-weight: 800;
				font-size: 1.4rem;
				margin: 1em 0;
			}
			h5 {
				font-weight: 800;
				font-size: 1.3rem;
				margin: 1em 0;
			}

			blockquote {
				border-left: 3px solid rgb(41 41 41);
				padding-left: 1.5em;
				font-style: italic;
			}
			figure {
				display: flex;
				flex-direction: column;
				justify-content: center;
				img {
					margin: auto;
				}
			}
			figcaption {
				color: rgba(117, 117, 117, 1);
				margin-top: 1em;
				font-size: 1rem;
				text-align: center;
			}
		}
	}

	.about--author {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
		gap: 1rem;

		img {
			width: 90px;
			border-radius: 50%;
		}

		${maxQuery('md')} {
			flex-direction: column;
		}

		.about {
			display: flex;
			flex-direction: column;
			gap: 0.6rem;

			h4 {
				font-weight: 700;
				font-size: 1.3rem;
				line-height: 2.5rem;
				letter-spacing: 0.02em;
			}
			div {
				font-weight: 400;
				font-size: 1rem;
				color: #126da9;
				cursor: pointer;
			}
		}
	}

	.related {
		position: relative;
		margin-top: 3rem;
		padding: 3rem 0;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			width: 100vw;
			min-width: 320px;
			left: 50%;
			z-index: 1;
			transform: translateX(-50%);
			background: #f2fcff;
			height: 100%;
			z-index: -1;
		}
		h3 {
			font-size: 2rem;
			font-weight: 700;

			${maxQuery('md')} {
				font-size: 1.7rem;
			}
			${maxQuery('sm')} {
				font-size: 1.5rem;
			}
		}

		.cards {
			margin-top: 1.5rem;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
			gap: 56px 20px;

			${maxQuery('sm')} {
				grid-template-columns: 1fr;
			}
		}
	}

	.newsletter {
		margin: 3.5rem 0;
	}
`;


