import { combineReducers } from 'redux';

const initState = {
	events: false,
	jobOpenings: [],
	useLayout: true,
};

const AppReducer = (state = initState, action) => {
	const { type, data } = action;

	switch (type) {
		case 'ALT_BLOGS':
			return {
				...state,
				blogs: data,
			};
		case 'ALT_BOOKS':
			return {
				...state,
				books: data,
				};
		case 'SET_USE_LAYOUT':
			return {
				...state,
				useLayout: data,
			};
		default:
			return state;
	}
};

const rootReducer = combineReducers({
	AppReducer,
});

export default rootReducer;
