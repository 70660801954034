import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { Button } from '../../UI';
import { AppRoutes } from '../../constants';

function NotFound() {
	return (
		<Container>
			<div>
				<h1>404</h1>
				<h4>Page not found</h4>
				<p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
				<div className="button">
					<Link to={AppRoutes.home}>
						<Button>Back to homepage</Button>
					</Link>
				</div>
			</div>
		</Container>
	);
}

export default NotFound;
