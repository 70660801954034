import styled from 'styled-components';
import { maxQuery } from '../../../helpers';

export const Container = styled.div`
	position: relative;
	padding: 3.9375rem 0 2rem 0;
	margin-top: 5rem;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		width: 100vw;
		min-width: 320px;
		left: 50%;
		transform: translateX(-50%);
		background: #f2fcff;
		height: 100%;
		z-index: -1;
	}

	h3 {
		margin: 3.5rem 0 1rem 0;
	}

	.categories-container {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;

		${maxQuery('sm')} {
			gap: 0.7rem;
		}
	}

	.post-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 56px 20px;

		${maxQuery('sm')} {
			grid-template-columns: 1fr;
		}
	}
`;

export const Category = styled.button`
	font-size: 1.1rem;
	font-weight: 600;
	background: transparent;
	border: none;
	/* outline: none; */
	padding: 3px 10px;
	position: relative;
	color: #64748b;
	text-transform: capitalize;
	cursor: pointer;
	transition: color 0.2s ease-in-out;

	::before {
		content: '';
		width: 100%;
		height: 3px;
		border-radius: 10px;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: transparent;
		transition: background-color 0.2s ease-in-out;
	}

	&.active,
	:focus,
	:hover {
		color: #2f9fe9;

		::before {
			background-color: #2f9fe9;
		}
	}
	${maxQuery('md')} {
		font-size: 1rem;
	}
`;
