import React, { useState } from 'react';
import { ReactComponent as ArrowRight } from '../../assets/svgs/NextArrow.svg';
import { Container } from './styles';

const Pagination = ({ itemsPerPage, length, setCurrentPage, currentPage, scrollPageToView }) => {
	const [pageLimit] = useState(5);
	const [maxPageLimit, setMaxPageLimit] = useState(5);
	const [minPageLimit, setMinPageLimit] = useState(0);
	const pages = [];

	for (let i = 1; i <= Math.ceil(length / itemsPerPage); i++) {
		pages.push(i);
	}

	const renderPage = pages.map((page) => {
		if (page < maxPageLimit + 1 && page > minPageLimit) {
			return (
				<button
					key={page}
					className={`page-number ${currentPage === page ? 'active' : ''}`}
					onClick={() => handlePageChange(page)}
				>
					{page}
				</button>
			);
		} else {
			return null;
		}
	});

	const handlePageChange = (page) => {
		scrollPageToView();
		setCurrentPage(Number(page));
	};

	const handlePrev = () => {
		scrollPageToView();
		setCurrentPage((prevPage) => prevPage - 1);
		if ((currentPage - 1) % pageLimit === 0) {
			setMaxPageLimit(maxPageLimit - pageLimit);
			setMinPageLimit(minPageLimit - pageLimit);
		}
	};

	const handleNext = () => {
		scrollPageToView();
		setCurrentPage((prevPage) => prevPage + 1);

		if (currentPage + 1 > maxPageLimit) {
			setMaxPageLimit(maxPageLimit + pageLimit);
			setMinPageLimit(minPageLimit + pageLimit);
		}
	};

	let pageIncrementBtn = null;
	if (pages.length > maxPageLimit) {
		pageIncrementBtn = (
			<button className="page-number page-dots" onClick={handleNext}>
				. . .
			</button>
		);
	}

	let pageDecrementBtn = null;
	if (minPageLimit >= 1) {
		pageDecrementBtn = (
			<button className="page-number page-dots" onClick={handlePrev}>
				. . .
			</button>
		);
	}

	return (
		<Container>
			<div className="page-btn-container">
				<button
					className="prev-btn"
					onClick={handlePrev}
					disabled={currentPage === pages[0] ? true : false}
				>
					<ArrowRight className="rotate-prev" /> <span>Previous</span>
				</button>

				<div className="page-number-container">
					{pageDecrementBtn}
					{renderPage}
					{pageIncrementBtn}
					<p className="pages-mobile">
						Page {currentPage} of {pages.length}
					</p>
				</div>

				<button
					className="next-btn"
					onClick={handleNext}
					disabled={currentPage === pages[pages.length - 1] ? true : false}
				>
					<span>Next</span> <ArrowRight />
				</button>
			</div>
		</Container>
	);
};

export default Pagination;
