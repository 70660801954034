import mixins from './mixins';

const colors = {
	// NEUTRAL
	neutral8: "#FCFCFD",
	neutral50: "#F8F5F2",
	neutral100: "#E8E8E8",
	neutral200: "#E0E0E0",
	neutral300: "#D8D8D8",
	neutral400: "#D1D1D1",
	neutral500: "#C1C1C1",
	neutral600: "#919191",
	neutral700: "#797979",
	neutral800: "#616161",
	neutral900: "#555555",
  
	// GRAY
	gray800: "#1D2939",
  
	// PRIMARY
	primary50: "#FEF5F4",
	primary100: "#FCD7D4",
	primary200: "#F8AFA8",
	primary300: "#F47D72",
	primary400: "#F04B3C",
	primary500: "#EE3726",
	primary600: "#E02312",
	primary700: "#A0190D",
	primary800: "#600F08",
	primary900: "#200503",
  
	// SECONDARY-ONE
	secondaryOne50: "#FFFCF6",
	secondaryOne100: "#FEF2D9",
	secondaryOne200: "#FCE6B3",
	secondaryOne300: "#FBD98E",
	secondaryOne400: "#F9C95E",
	secondaryOne500: "#F8C042",
	secondaryOne600: "#F7B31C",
	secondaryOne700: "#BD8507",
	secondaryOne800: "#725004",
	secondaryOne900: "#261B01",
  
	// SECONDARY-TWO
	secondaryTwo50: "#EDFEFF",
	secondaryTwo100: "#B8FDFF",
	secondaryTwo200: "#70FAFF",
	secondaryTwo300: "#70FAFF",
	secondaryTwo400: "#00B7BD",
	secondaryTwo500: "#009499",
	secondaryTwo600: "#008286",
	secondaryTwo700: "#005D60",
	secondaryTwo800: "#003839",
	secondaryTwo900: "#001213",
  
	// SUCCESS
  
	success50: "#ECFDF5",
	success500: "#009946",
  
	//    WARBING
	warning50: "#FFFBEB",
	warning500: "#F59E0B",
  
	//   ERROR
	error50: "#FEF2F2",
	error500: "#EF4444",
  
	//   SHADES
	black: "#000000",
	white: "#FFFFFF",
  
	// DARK MODE
	darkMode50: "#3A3C43",
	darkMode100: "#373A41",
	darkMode200: "#35373E",
	darkMode300: "#30333A",
	darkMode400: "#2E3138",
	darkMode500: "#292C33",
	darkMode600: "#272A31",
	darkMode700: "#24272F",
	darkMode800: "#20232A",
	darkMode900: "#14171F",
  };

const dimensions = {
	navHeight: '5rem',
	navHeightMobile: '3rem',
	maxWidth: '1100px',
	adminTopNavHeight: '4.5rem',
	footerHeight: '3.5rem',
};

export const BREAKPOINTS = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
};

const theme = () => ({
	colors,
	primary: '#188DAF',
	bgColor: '#fff',
	textColor: '#050402;',
	mixins,
	dimensions,
	fontFamily: 'Nunito Sans',
	secondary: '#051A26',
});

export default theme;
