import { Spin } from 'antd';
import { Container } from './styles';
import "antd/dist/antd.css"; 

const LoadingScreen = ({ fullScreen, tip }) => {
   const getRandomMessage = () => randomTips[Math.floor(Math.random()*randomTips.length)]
	
	return (
		<Container fullScreen={fullScreen}>
			<Spin size="large">
				<div className="content" />
			</Spin>
			<span className="tip">{tip ? tip : getRandomMessage()}</span>
		</Container>
	);
};

export default LoadingScreen;

const randomTips = ['Loading...'];
