import React from 'react';



const Coaching = () => {
    //fontFamily: 'Nunito Sans',
    return (
        <div className='m5' style={{ marginTop: "120px", fontFamily: 'Nunito Sans' }}>
            <section className='m-5 justify-content-center'>

                <h1 className='text-center'>6-Month Personalized Coaching Program</h1>
                <p className='mt-5'><strong className='text-dark '>6-month Private Coaching</strong></p> <br />

                <p>Ready to propel your coding skills in Python, FastAPI, Django, and Frontend development to the next level? If you're aiming to code more efficiently, increase your technical proficiency, and experience greater satisfaction in your programming endeavors, this coaching program is specifically crafted for aspiring developers like you.</p>

                <p>Feeling uncertain about how to progress? Maybe you've attempted self-study, tackled coding challenges, or even built some projects. However, you sense there's more to learn and refine.</p>

                <p className=''>
                    Allow me to guide you through a structured program designed to uncover your strengths, enhance your coding abilities, and provide a clear path for your technical growth.


                </p>

                <h2 className='mt-3'>Comprehensive Guidance Throughout</h2><br />

                <p>
                    Envision cultivating a deep understanding of coding principles and technologies. Picture working on meaningful projects that excite you, as challenges transform into opportunities. Take a transformative step forward in your coding journey.
                </p>

                <h2 className='mt-3'>What to Expect:</h2><br />
                <ul>
                    <li>Optimize your coding efficiency while mastering Python, FastAPI, Django, and Frontend technologies.</li>
                    <li>Develop your coding prowess and problem-solving skills to tackle complex projects.</li>
                    <li>Chart a clear path for your coding career, outlining your growth and future steps.</li>
                </ul>

                <h2 className='mt-3'>How the Program Operates:</h2><br />
                <ul>
                    <li><strong>Big picture:</strong> Enjoy six months of direct access to me via email, text, and phone. Feel free to ask any coding-related question at any time.</li>
                    <li><strong>Coaching Kickoff:</strong>  Begin with a comprehensive assessment to understand your current coding skills, project goals, and the necessary steps for success.</li>
                    <li><strong>Weekly Coding Assignments:</strong>  Receive structured coding tasks and a strategic plan to implement them in real-world projects.</li>
                    <li><strong>Code Review Sessions:</strong> Bi-weekly coding sessions to review your progress, provide feedback, and make adjustments as needed.</li>
                    <li><strong>Forever Mentorship:</strong> After the 6 month period, you can always email me with any other questions.</li>
                </ul>

               

                <h2 className='mt-3'>Price: $600</h2><br />
            </section>

            <div className="light-rounded-buttons text-center">
                <a href="https://forms.gle/8hyYe4r9CJZerQaHA" target="_blank" className="btn primary-btn-outline" rel="noopener noreferrer">Get Coaching Assistance</a>
            </div>

        </div>





    );
};

export default Coaching;
