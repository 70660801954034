import styled from 'styled-components';
import { NotFoundBg } from '../../assets/png';
import { maxQuery } from '../../helpers';

export const Container = styled.div`
	position: relative;
	height: calc(100vh - ${({ theme }) => theme.dimensions.navHeight});
	isolation: isolate;
	display: grid;
	align-items: center;
	place-content: center;

	&::after {
		content: '';
		position: absolute;
		height: 100%;
		top: 0;
		width: 100vw;
		left: 50%;
		z-index: -1;
		transform: translateX(-50%);
		background-size: cover;
		background-image: url(${NotFoundBg});
		background-repeat: no-repeat;
		background-position: center;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		h1 {
			font-size: 12.5rem;
			line-height: 120%;
			letter-spacing: 0.02em;
			color: #f71735;
			opacity: 0.7;
			${maxQuery('md')} {
				font-size: 8.5rem;
			}
			${maxQuery('sm')} {
				font-size: 6.25rem;
			}
		}

		h4 {
			font-weight: 800;
			font-size: 2.5rem;
			line-height: 120%;
			letter-spacing: 0.02em;
			${maxQuery('md')} {
				font-size: 2.2rem;
			}
			${maxQuery('sm')} {
				font-size: 2.0625rem;
			}
		}

		p {
			padding-top: 0.6rem;
			font-weight: 800;
			font-size: 1.125rem;
			line-height: 120%;
			color: #201f1f;
			${maxQuery('md')} {
				font-size: 0.95rem;
			}
			${maxQuery('sm')} {
				font-size: 0.875rem;
			}
		}

		.button {
			margin-top: 3rem;
		}
	}
`;
