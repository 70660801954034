import { useState, useRef } from 'react';
import Pagination from '../../../UI/Pagination';
import { Category, Container } from './styles';
import Blog from '../../../component/Blog';

const Recent = ({ recentBlogs, categories }) => {
	const [currentCategory, setCurrentCategory] = useState('all');
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(10);

	const pageRef = useRef(null);

	const indexOfLastItem = currentPage * postsPerPage;
	const indexOfFirstItem = indexOfLastItem - postsPerPage;

	const filteredPost = recentBlogs?.filter((blog) =>
		currentCategory === 'all' ? blog : blog?.fields?.category === currentCategory
	);

	const handleScrollIntoView = () => {
		pageRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Container ref={pageRef}>
			<div className="categories-container">
				{categories?.map((category) => (
					<Category
						className={currentCategory === category ? 'active' : ''}
						key={category}
						onClick={(e) => {
							setCurrentPage(1);
							setCurrentCategory(category);
						}}
					>
						{category}
					</Category>
				))}
			</div>

			<h3 className="u--typo__title2">Recent Posts</h3>

			<div className="post-container">
				{filteredPost?.slice(indexOfFirstItem, indexOfLastItem)?.map((blog) => (
					<Blog key={blog?.sys?.id} blog={blog} />
				))}
			</div>

			<Pagination
				itemsPerPage={postsPerPage}
				length={filteredPost?.length}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				scrollPageToView={handleScrollIntoView}
			/>
		</Container>
	);
};

export default Recent;
