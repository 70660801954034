import styled from 'styled-components';
import { maxQuery } from '../../../helpers';

export const Container = styled.div`
	.featured-title {
		position: relative;

		h1 {
			position: relative;
			z-index: 1;
			margin-bottom: 1rem;
		}

		img {
			position: absolute;
			top: 1.1em;
			left: -1em;

			${maxQuery('md')} {
				left: -3%;
				/* right: 92.53%; */
				top: -15%;
				/* bottom: 95.52%; */
			}
		}
	}
`;
